.title--blue {
    margin-top: 20px;
    height: 42px;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #1F4D7B;
}

.title--blue--1 {
    color: #1F4D7B !important;
    font-weight: bold;
}

.text--blue--1 {
    color: #1F4D7B !important;
}

label,
select {
    color: #1F4D7B;
}

.input--style {
    color: #1F4D7B !important;
    border-color: #1F4D7B !important;
}

.button--primary {
    color: #1F4D7B !important;
    border-color: #1F4D7B !important;
}

.card--header {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: white !important;
    background-color: #1F4D7B !important
}

.card--header--1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #1F4D7B !important;
    background-color: rgba(31, 77, 123, 0.3) !important
}

.border--lr td {
    border-left: 1px solid #979797 !important;
    border-right: 1px solid #979797 !important;
}

.border--bt th {
    border-bottom: 1px solid #979797 !important;
}

.border--last td {
    border-bottom: 1px solid #979797 !important;
}

.border-l {
    border-left: 1px solid #979797 !important;
}

.border-r {
    border-right: 1px solid #979797 !important;
}

.all-borders {
    border: 1px solid #979797;
}

.tabla-resumen::-webkit-scrollbar {
    height: 7px;
}

.tabla-resumen::-webkit-scrollbar-thumb {
    background-color: #1F4D7B;
    border-radius: 5px;
}

.nav--link--color {
    color: #1F4D7B !important;
}

.table .thead--blue th {
    color: #fff;
    background-color: #1F4D7B;
    border-color: #979797;
}

.table .tr--red td {
    color: #fff;
    background-color: #EC6D51;
    border-color: #979797;
}

.thead--blue--individual {
    color: #fff;
    background-color: #1F4D7B;
    border-color: #979797;
}

.resources--table tbody {
    color: #1F4D7B;
}

.td--individual {
    color: #1F4D7B;
}

.add--button {
    background-color: #3579F6 !important;
    color: white !important;
}

.content--table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content--table thead tr {
    background-color: #1F4D7B;
    color: #fff;
    text-align: left;
    font-size: 15px;
}

.content--table th {
    padding: 12px 15px;
    border-right: 1px solid #D1D1D1;
}

.content--table td {
    padding: 5px 10px;
    border-right: 1px solid #D1D1D1;
}

.content--table tbody tr {
    border-bottom: 1px solid #D1D1D1;
    color: #1F4D7B;
    font-size: 12px;
}

.content--table tbody tr:last-of-type {
    border-bottom: 2px solid #1F4D7B;
}

.content--table--1 table:first-child {
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content--table--1 thead tr {
    background-color: #1F4D7B;
    color: #fff;
    text-align: left;
    font-size: 20px;
}

.content--table--1 th, .content--table--1 td {
    padding: 12px 15px !important;
    border-right: 1px solid #D1D1D1;
}

.content--table--1 tbody tr {
    border-bottom: 1px solid #D1D1D1;
    color: #1F4D7B;
    font-size: 15px;
}

.content--table--1 tbody tr:last-of-type {
    border-bottom: 2px solid #1F4D7B;
}

._19jgW::-webkit-scrollbar {
    height: 7px;
}

._19jgW::-webkit-scrollbar-thumb {
    background-color: #1F4D7B;
    border-radius: 5px;
}